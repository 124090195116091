body.darkmode{
    --bg-body:#121212;
    --colormain: #2B2B2B;
    //--hover: #E5E5E5;
    --color-text-1:#F7F7F7;
    --color-text-2:#E5E5E5;
    --color-1: #FC7321;
    --color-2: #0065D3; 
    --color-3: #2B2B2B; 
    --color-border: rgba(255, 255, 255, 0.15); 
    
    .list-nav{
        color: #F7F7F7;
    }
    .list-nav .item-nav.active a, .list-nav .item-nav:hover a{
        color: #fff;
        background: #121212;
    }
    .section-gallery{
        background: #2B2B2B;
    }
    .section-gocnhin{
        background: url(images/graphics/bg_gocnhin_darkmode.png) no-repeat;
    }
    .list-podcast{
        .art_item{
            background: #2B2B2B;
            border-color: #2B2B2B;
        }
        .bottom-podcast{
            color: #F7F7F7;
            .time{
                color: #E5E5E5;
            }
        }
    }
    .box-editors-pick{
        background: #2B2B2B;
    }
    .social{
        .icon-ct{
            color: #F7F7F7;
        }
    }
    .top-footer{
        background: #2B2B2B;
    }
    .footer{
        background: #121212;
    }
    .section-folder-podcast{
        background: url(images/graphics/folder_podcast_darkmode.png) no-repeat;
        background-position: top center;
    }
    &.page-longform{
        .list-podcast{
            .art_item{
                background: #121212; 
                border-color: #121212;
            }
            &.list .content{
                background: #2B2B2B;
            }
        }
    }
    .readmore .inner-readmore{
        //color: $colormain;
        border-color: $colormain;
    }
    .section-detail-video{
        .right-detail{
            background: #2A2A2A;
        }
        .meta-detail{
            .cat, .date-time{
                color: #BDBDBD;
            }
        }
    }
    .count-view{
        color: #F7F7F7;
    }
    .list-video{
        .art_item{
            &.feature{
                .content{
                    background: #2B2B2B;
                }
            }
        }
    }
    input, input[type="text"], input[type="password"], input[type="email"], input[type="tel"], textarea, select.form-control,
    .input-select{background: #2B2B2B;border-color: #2B2B2B;color: #E5E5E5;}
    .text-selected{
        color: #E5E5E5;
    }
    .wrap-content-select{
        background: #2B2B2B;color: #E5E5E5;
    }
    &.folder-nguoi-fpt{
        background: #121212;
    }
    .gocnhin .list-art .art_item.featured{
        background: #2B2B2B;
    }
    @import "darkmode-detail.scss";
    .ss-scroll{
        background: #5a5a5a;
    }
    .section-detail-video .right-detail .box-area-input{
        background: #4B4B4B;
        border-color: #4B4B4B;
        textarea{
            background: #4B4B4B;
        }
    }
    .slide-author{
        background: #2B2B2B;
    }
    .file-input{
        border: none;
        background: #2B2B2B;
        .icon{background: #121212;}
    }
    .logo-footer{
        background: url(../images/graphics/logo_chungta_white.svg) no-repeat;
        background-size: 100%;
        img{display: none;}
    }
    
    @media(max-width:600px){
        .box-area-input{
            background: none;
        }
    }
}