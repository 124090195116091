&.page-detail{
    background: #121212;
    .main-content{
        background: #121212;
    }
    .list-related{
        .item-related{
            color: #BDBDBD;
        }
    }
}
.fck_detail table.tplCaption td.caption, .fck_detail table.tplCaption td.Image, .fck_detail table.tplCaption td p.Normal, .fck_detail table.tplCaption td .Image, .fck_detail .tplCaption .Image{background: #2B2B2B;}
&.detail-image .desc_cation, &.detail-infographic .desc_cation{
    background: #2B2B2B;
}
&.detail-longform .section-bottom-detail{
    background: #121212;
}
.header-detail{
    .time{
        color: #BDBDBD;
    }
}
.social_pin{
    .item-social{
        background: #2B2B2B;
        border-color: #2B2B2B;
        .icon-ct{
            color: #F7F7F7;
        }
        &:hover{
            border-color: $color-2;
            .icon-ct{
                color: $color-2;
            }
        }
    }
}
.section-tags .item-tag{
    background: #2B2B2B;
    border-color: #2B2B2B;
}

.section-vote-detail,.related-category{
    .main-content{
        background: #2B2B2B;
    }
}
.box-vote-detail .inner-item-vote{
    background: linear-gradient(180deg, #000000 0%, rgba(43, 43, 43, 0) 100%);
}
.form-interview{
    background: #2B2B2B;
    border-radius: 3px;
    input, input[type="text"], input[type="password"], input[type="email"], input[type="tel"], textarea, select{
        background: #121212;
    }
}
.header-timeline{
    background: #2B2B2B;
}
.box-dienbien{
    background: #2B2B2B;
}

/*Podcast*/
.section_podcast_detail{
    background: url(images/graphics/detail_podcast_dark.jpg) no-repeat;
    background-position: top center !important;
    @media(max-width:600px){
        background: url(images/graphics/detail_podcast_mb_dark.jpg) no-repeat;
    }
    .social .item-social{
        background: #121212;
        border-color: #121212;
        color: #F7F7F7;
    }
}
.afp-position, .afp-duration, .afp-slash{
    color: #E5E5E5;
}
.afp-sound-level-2, .afp-sound-level-2:hover,
.afp-next10sbtn, .afp-back10sbtn{
    filter: brightness(4);
}
.section-exitintent{
    background: #2B2B2B;
}
/*End Podcast*/

/*Comment*/
.comment_item .content-comment{
    color: #F7F7F7;
}
.box-area-input{
    background: #2B2B2B;
    border-color: #2B2B2B;
}
.input_comment textarea.block_input{
    color: #E5E5E5!important;
    @include placeholder {
      color: #E5E5E5;
    }
}
.comment_item a.link_thich{
    color: #E5E5E5 !important;
}
.comment_item .block_like_web a{
    color: #E5E5E5 !important;
}
.input_comment textarea.block_input{border: none;}
/*End Comment*/