%flexbox{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
.wrap-popup{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    @extend %flexbox;
    align-items: center;
    justify-content: center;
    padding: 15px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;
    .inner-popup{
        width: 100%;
        max-width: 475px;
        background: #fff;
        border-radius: 6px;
        position: relative;
        z-index: 1;
        padding: 40px;
    }
    .header-popup{
        font-size: 20px;
        line-height: 1;
        color: var(--color-text-1);
        text-align: center;
        margin-bottom: 24px;
    }
    .close-popup{
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background: #fff;
        color: #4F4F4F;
        position: absolute;
        right: -10px;
        top: -10px;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
        @extend %flexbox;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        line-height: 1;
        font-family: -webkit-body;
        cursor: pointer;
    }
}
.overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.8);
    cursor: pointer;
}

/*Open Popup*/
.open-popup-login{
    .wrap-popup-login{
        opacity: 1;
        visibility: visible;
    }
    .main-nav{
        z-index: 0;
    }
}

.open-popup-notify{
    .wrap-popup-notify{
        opacity: 1;
        visibility: visible;
    }
    .main-nav{
        z-index: 0;
    }
}
/*End Open Popup*/

///////////////
.popup-login{
    padding: 40px 80px 60px !important;
    .text-selection{
        text-align: center;
        font-size: 16px;
        line-height: 1.18;
    }
    @media(max-width:600px){
        padding: 40px 15px 75px !important;
    }
}
.form-login{
    input{
        font-size: 14px;
        @include placeholder {
          color: #757575 !important;
        }
    }
    .row-input{
        margin-bottom: 24px;
    }
    .btn{
        width: 100%;
        margin-bottom: 20px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: var(--colormain);
        color: #fff;
        font-size: 14px;
        border: none;
        outline: none;
        border-radius: 3px;
    }
    
}
.gr-btn-social{
    width: 100%;
    float: left;
    .btn-social{
        width: 100%;
        height: 40px;
        line-height: 40px;
        float: left;
        text-align: center;
        color: #fff;
        font-size: 13px;
        border-radius: 3px;
        .icon-ct{
            font-size: 20px;
            margin-right: 8px;
        }
    }
    .fb{
        background: #4764AB;
        margin-bottom: 12px;
    }
    .gg{
        background: #BC2020;
    }
}

/*Notify*/
.wrap-popup-notify{
    text-align: center;
    .popup-notify{
        max-width: 375px;
    }
    .icon{
        width: 71px;
        height: 71px;
        background: url(images/graphics/icon_check.svg) no-repeat;
        display: inline-block;
        margin-bottom: 10px;
    }
    .title{
        font-size: 20px;
        line-height: 1.1;
        margin-bottom: 10px;
    }
    .note{
        font-size: 14px;
        line-height: 1.28;
        color: var(--color-text-2);
    }
}
/*End Notify*/

body.darkmode{
    .wrap-popup{
        .inner-popup{
            background: #2A2A2A;
        } 
        .close-popup{
            background: #a2a2a2;
        }
        input[type="text"], input[type="password"], input[type="email"], input[type="tel"], textarea, select.form-control{
            background: #848484;
        }
        .form-login .btn{
            background: $colormain;
        }
    }
}
