.page-search{
    .main-content{
        min-height: calc(100vh - 573px);
    }
}
.header-page{
    &.no-line{
        border-bottom: none;
        padding-bottom: 0;
    }
}
.form-search{
    width: 100%;
    float: left;
    margin-bottom: 30px;
    .input-search{
        margin-bottom: 24px;
    }
    .wrap-input{
        width: 100%;
        max-width: 142px;
        margin-right: 24px;
        float: left;
        & > .text{
            display: block;
            margin-bottom: 14px;
            color: var(--color-text-2);
            font-size: 14px;
            line-height: 1.21;
        }
    }
}
.input-search{
    position: relative;
    width: 100%;
    height: 40px;
    display: block;
    .btn-search{
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        border: none;
        outline: none;
        color: #757575;
        font-size: 17px;
    }
    input{
        padding-right: 40px;
        border: 1px solid #D2D2D2;

    }
}
.input-select {
    height: 40px;
    border-radius: 3px;
    background: #fff;
    padding-right: 10px;
    border: 1px solid #C8C8C8;
    position: relative;
    cursor: pointer;
}
.style-select{
    &:before {
        content: '';
        width: 7px;
        height: 7px;
        border-right: 2px solid #878686;
        border-bottom: 2px solid #878686;
        transform: rotate(45deg);
        position: absolute;
        right: 16px;
        top: 13px;
    }
    &.active{
        .wrap-content-select {
            opacity: 1;
            visibility: visible;
        }
    }
}
.text-selected {
    max-width: 150px;
    width: 100%;
    height: 100%;
    @extend %flexbox;
    align-items: center;
    padding: 0 12px;
    font-size: 14px;
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
}

.wrap-content-select {
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    background: #fff;
    border-radius: 3px;
    padding: 12px 18px; 
    color: #56616F;
    font-size: 14px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 2;
    .row-slect{
        width: 100%;
        float: left; 
        position: relative;
        a{
            display: block;
            padding: 6px 10px 6px 0;
        }
    }
}