.footer{
    background: #fff;
}
.top-footer{
    background: #F8F8F8;
    height: 45px;
    .container{
        align-items: center;
        height: 100%;
    }
    .contact{
        align-items: center;
        .icon-ct{
            margin-right: 11px;
        }
    }
    .hotline{
        @extend .contact;
        margin-left: 8px;
        padding-left: 8px;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
    }
    .social{
        margin-left: auto;
        .item-social{
            width: 20px;
            height: 20px;
            @extend %flexbox;
            align-items: center;
            cursor: pointer;
            margin-left: 16px;
            margin-right: 0;
            .icon-ct{
                font-size: 16px;
            }
        }
    }
}
.bottom-footer{
    padding: 30px 15px;
    .col-left{
        width: 29.09090909090909%;
        color: var(--color-text-2);
        line-height: 1.38;
        font-size: 13px;
    }
    .col-right{
        width: 70.90909090909091%;
    }
}
.logo-footer{
    width: 135px;
    height: 31px;
    margin-bottom: 14px;
    display: inline-block;
    img{
        width: 100%;
        float: left;
    }
}
.copyright{
    margin-bottom: 40px;
}
.link-fpt{
    align-items: center;
}
.logo-fpt{
    width: 34px;
    margin-left: 10px;
    display: inline-block;
    img{
        width: 100%;
        float: left;
    }
}
.cat-footer{
    width: 16.67%;
    .item-cat{
        font-size: 14px;
        font-weight: 600;
        line-height: 1.14;
    }
    .item-cat-sub{
        font-size: 13px;
        color: var(--color-text-2);
        &:hover{
            color: $colormain;
        }
    }
    a{
        display: block;
        &:not(:last-child){
            margin-bottom: 10px;
        }
        &:hover{
            color: $colormain;
        }
    }
    
}
.wrap-link{
    .item-link{
        font-size: 16px;
        font-weight: 700;
        display: block;
        &:first-child{
            margin-bottom: 30px;
        }
    }
}