.wrap-box-folder{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:30px;
    font-family: $font-title;
    margin-bottom: 30px;
    &.line-bottom{
        border-bottom: 1px solid var(--color-border);
        padding-bottom: 30px;
    }
    &.style-1{
        grid-gap:24px 30px;
        .art_item{
            &:not(.feature):not(:last-child){
                border-bottom: 1px solid var(--color-border);
                padding-bottom: 24px;
            }
            &.feature{
                grid-column: 1 / 3;
                grid-row: 1 / 3;
                .title-news{
                    font-size: 26px;
                    margin-bottom: 16px;
                }
                .lead{
                    font-size: 16px;
                    line-height: 1.4;
                    color: var(--color-text-2);
                }
            }
        }
        .thumb_art{
            margin-bottom: 16px;
        }
        .title-news{
            font-size: 20px;
            line-height: 1.2;
        }
        &.no-line-item{
            .art_item{
                border-bottom: none !important;
                padding-bottom: 0 !important;
            }
        }
        &.with-cat{
            .cat{
                margin-top: 8px;
            }
        }
    }
    &.style-2{
        .art_item{
            width: 100%;
            float: left;
            &.feature{
                grid-row:1 / 3;
                .content{
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 25px 16px;
                    color: #fff;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                    .cat{
                        color: #fff;
                        &:hover {
                            color: $colormain;
                        }
                    }
                }
                .title-news{
                    width: 100%;
                    font-size: 20px;
                    line-height: 1.3;
                    margin-bottom: 16px;
                    color: #fff;
                    &:hover a{
                        color: #DADADA;
                    }
                }
            }
        }
        .col{
            .art_item{
                &:not(:last-child){
                    margin-bottom: 30px;
                }
                &:first-child{
                    margin-bottom: 0;
                    .thumb_art{
                        width: 100%;
                        margin-bottom: 16px;
                    }
                    .title-news{
                        font-size: 18px;
                        line-height: 1.33;
                        margin-bottom: 10px;
                    }
                    .cat{
                        margin-bottom: 13px;
                    }
                }
                .thumb_art{
                    width: 134px;
                    float: left;
                    margin-right: 16px;
                    margin-bottom: 0;
                }
            }
        }
        .col-1{
            grid-row:1 / 3;
        }
        .row{
            grid-column: 2 / 4;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap:30px;
            .thumb_art{
                margin-bottom: 16px;
            }
            .title-news{
                font-size: 18px;
                line-height: 1.33;
                margin-bottom: 10px;
            }
            &.row-1{
                grid-row:1;
            }
            &.row-2{
                grid-row:2 / 2;
                @media(max-width:1000px){
                    display: block;
                    .art_item{
                        &:not(:last-child){
                            margin-bottom: 24px;
                        }
                    }
                }
                .thumb_art{
                    width: 135px;
                    margin: 0 16px 0 0;
                    float: left;
                }
                .title-news{
                    font-size: 14px;
                    line-height: 1.2;
                }
            }
        }
    }
    &.style-3{
        .art_item{
            &.feature{
                grid-column: 1 / 3;
                .thumb_art{
                    margin-bottom: 0;
                }
                .title-news{
                    color: #fff;
                    &:hover a{
                        color: #DADADA;
                    }
                }
                .content{
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 24px;
                    color: #fff;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                    .cat{
                        color: #fff;
                        &:hover{
                            color: $colormain;
                        }
                    }
                }
                .title-news{
                    font-size: 28px;
                    line-height: 1.1;
                    margin-bottom: 16px;
                }
            }
            .thumb_art{
                margin-bottom: 16px;
            }
            .title-news{
                font-size: 20px;
                line-height: 1.3;
                margin-bottom: 16px;
            }
            .lead{
                font-size: 16px;
                line-height: 1.42;
                margin-bottom: 8px;
                color: var(--color-text-2);
            }
        }
    }
    &.style-4{
        @extend .style-1;
        grid-gap: 30px;
        .art_item{
            &.feature{
                grid-column: 2 / 4;
                .lead{
                    margin-bottom: 16px;
                }
            }
            &:not(.feature):not(:last-child){
                border-bottom: none;
                padding-bottom: 0;
            }
        }
        .title-news{
            margin-bottom: 8px;
        }
    }
    &.style-5{
        grid-template-columns: repeat(4, 1fr);
        grid-gap:30px;
        .art_item{
            &:nth-child(1){
                grid-column: 1 / 3;
            }
            &:nth-child(2){
                grid-column: 3 / 5;
            }
            &:nth-child(1), &:nth-child(2){
                .title-news{
                    font-size: 24px;
                    line-height: 1.33;
                    margin-bottom: 16px;
                }
            }
        }
        .thumb_art{
            margin-bottom: 16px;
        }
        .title-news{
            font-size: 20px;
            line-height: 1.25;
            margin-bottom: 6px;
        }
    }
    &.style-6{
        grid-template-columns: repeat(3, 1fr);
        grid-gap:30px;
        .art_item{
            &:nth-child(1){
                grid-column: 1 / 4;
                .thumb_art{
                    margin-bottom: 0;
                }
                .title-news{
                    width: 100%;
                    font-size: 30px;
                    line-height: 1.26;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 90px 24px 36px; 
                    color: #fff;
                    margin-bottom: 0;
                    border-radius: 3px;
                    &:hover{
                        color: #DADADA;
                    }
                    a{
                        &:hover{
                            color: #DADADA;
                        }
                    }
                }
            }
            
        }
        .thumb_art{
            margin-bottom: 16px;
        }
        .title-news{
            font-size: 20px;
            line-height: 1.3;
            margin-bottom: 16px; 
        }
        .lead{
            font-size: 16px;
            color: var(--color-text-2);
        }
    }
    &.style-7{
        @extend %flexbox;
        grid-gap:24px;
        .col{
            width: 26.90909090909091%;
        }
        .col-mid{
            width: 42.72727272727273%;
            .title-news{
                font-size: 24px;
                margin-bottom: 18px;
            }
        }
        .art_item{
            &.small{
                width: 100%;
                float: left;
                border-top: 1px solid var(--color-border);
                margin-top: 24px;
                padding-top: 24px;
                .thumb_art{
                    width: 119px;
                    margin: 0 16px 0 0;
                    float: left;
                }
                .title-news{
                    font-size: 14px;
                }
                .icon-thumb{
                    width: 14px;
                    height: 14px;
                    left: 6px;
                    bottom: 6px;
                    .icon-ct{
                        font-size: 8px;
                    }
                }
            }
            &.full{
                &:first-child{
                    margin-bottom: 24px;
                }
            }
        }
        .thumb_art{
            margin-bottom: 16px;
        }
        .title-news{
            font-size: 18px;
            line-height: 1.2;
            margin-bottom: 8px;
        }
        .lead{
            font-size: 16px;
            color: var(--color-text-2);
            margin-bottom: 8px;
        }
    }
}
.content{
    border-radius: 5px;
}
.icon-thumb{
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.6);
    @extend %flexbox;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    left: 8px;
    bottom: 8px;
    .icon-ct{
        font-size: 16px;
    }
}
.list-art{
    width: 100%;
    float: left;
    font-family: $font-title;
    .art_item{
        width: 100%;
        float: left;
        display: block;
        &:not(:first-child){
            border-top: 1px solid var(--color-border);
            padding-top: 30px;
            margin-top: 30px;
        }
        .thumb_art{
            width: 250px;
            float: left;
            margin-right: 24px;
        }
        .title-news{
            font-size: 22px;
            line-height: 1.27;
            margin-bottom: 16px;
        }
        .lead{
            font-size: 16px;
            margin-bottom: 16px;
        }
    }
    &.grid{
        grid-gap: 30px;
        &.col-2{
            grid-template-columns: repeat(2, 1fr);
        }
        &.col-3{
            grid-template-columns: repeat(3, 1fr);
        }
        &.col-4{
            grid-template-columns: repeat(4, 1fr);
        }
        .art_item{
            margin: 0;
            padding: 0;
            border: none;
            .thumb_art{
                width: 100%;
                margin: 0 0 16px;
            }
            .title-news{
                font-size: 20px;
                line-height: 1.3;
            }
        }
    }
    &.line-bottom{
        border-bottom: 1px solid var(--color-border);
        margin-bottom: 30px;
        padding-bottom: 30px;
    }
}
.art_item{
    position: relative;
}