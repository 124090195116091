@mixin cubic-transition($delay, $duration, $property) {
  transition: {
    duration: $duration;
    property: $property;
    timing-function: cubic-bezier(.7, 1, .7, 1);
  }
}

%has_transition {
  @include cubic-transition($delay: 0, $duration: 200ms, $property: (all));
}

@mixin clearfix {
  clear: both;

  &::before,
  &::after {
    display: table;
    content: "";
    clear: both;
  }
}


/*@include cubic-transition($delay: 0, $duration: 350ms, $property: (transform)); */

@function px_to_rem($target, $context: $base-size) {
  @if $target==0 {
    @return 0
  }

  @return $target / $context+0rem;
}

$font-title: 'SFProDisplay',serif; 
$fontmain: 'SFProDisplay',serif; 
$font-detail:'Noto Serif', serif;
       
$base-size: 16px;
$lh: 1.313;
$lhbody: 1.45;
$colormain:#FC7321;
$color-1:#031533;
$color-2:#0065D3;
$color-solid:#E5E5E5;

$main-width:1110px;
$padding:15px;

:root {
    --bg-body:#fff;
    --color-text-1:#222;
    --color-text-2:#4F4F4F;
    --colormain: #FC7321;
    --hover: #FC7321;
    --color-1: #031533;
    --color-2: #0065D3;
    --color-3: #FFF7EF;
    --color-border: rgba(0,0,0,0.15);
}

%flexbox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

%clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

%font-fea {
  font-feature-settings: 'pnum'on, 'lnum'on;
  -webkit-font-feature-settings: 'pnum'on, 'lnum'on;
  -moz-font-feature-settings: 'pnum'on, 'lnum'on;
  -ms-font-feature-settings: 'pnum'on, 'lnum'on;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }
}
/*use*/
/*@include placeholder {
  color: #4F4F4F;
}*/

/*Mixin*/