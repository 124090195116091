.page-folder{
    .main-nav, .title-section{
        margin-bottom: 24px;
    }
    .section-podcast{
        background: url(images/graphics/bg_section_podcast.png) no-repeat;
        padding: 24px 0;
    }
}
/*Folder Kinh Doanh*/
.wrap-list-sub-folder{
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 30px;
    padding-top: 30px;
    .art_item{
        .title-news{
            line-height: 1.27;
            font-size: 22px !important;
        }
        .lead{
            margin-bottom: 0;
        }
    }
}
/*End Folder Kinh Doanh*/

/*folder-nguoi-fpt*/
.folder-nguoi-fpt{
    background: #FFF7EF;
    .box-editors-pick{
        background: #fff;
    }
}
.gocnhin{
    .main-content{
        margin: 0 auto;
    }
    .list-art{
        .art_item{
            padding: 0 36px;
            border-top: none;
            &.featured{
                background: #FFE3D2;
                border-radius: 3px;
                padding: 36px;
                .lead{
                    font-size: 14px;
                }
            }
            &:not(:first-child){
                margin-top: 24px;
                padding-top: 24px;
            }
            &:nth-child(2){
                padding-top: 0;
            }
            &:nth-child(n + 2){
                &:before{
                    content: '';
                    width: calc(100% - 72px);
                    height: 1px;
                    position: absolute;
                    left: 36px;
                    bottom: -24px;
                    background: var(--color-border);
                }
            }
            &:last-child:before{
                content: none;
            }
            .thumb_art{
                width: 265px;
                float: right;
                margin: 0 0 0 30px;
            }
        }
        .author{
            @extend %flexbox;
            align-items: center;
            .avatar{
                width: 30px;
                height: 30px;
                margin-right: 12px;
                img{
                    border-radius: 50%;
                }
            }
        }
    }
}
/*Author*/
.gocnhin{
    &.detail-author{
        .list-art{
            .art_item{
                padding: 0;
                &:nth-child(n+1){
                    margin: 24px 0 0;
                    padding: 24px 0 0;
                    &:before{
                        content: '';
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        left: 0;
                        bottom: auto;
                        top: 0;
                        background: var(--color-border);
                    }
                }
                &:first-child{
                    margin-top: 0;
                }
            }
        }
        .main-content{
            width: calc(100% - 430px);
            
        }
        .sidebar{
            width: 430px;
        }
        @media(max-width:1000px){
            display: block;
            .main-content{
                width: 100%;
            }
            .sidebar{
                display: block;
                width: 100%;
                padding: 0;
            }
        }
        @media(max-width:600px){
            display: block;
            .list-art{
                .art_item{
                    .thumb_art{
                        width: 100%;
                        margin: 0 0 16px;
                    }
                }
            }
        }
        
    }
}
.slide-author{
    background: #F2F2F2;
    border-radius: 3px;
    padding: 30px;
}
.header-slide-author{
    margin-bottom: 30px;
    align-items: center;
    .text{
        font-size: 24px;
        font-weight: 700;
    }
    .gr-btn-slide{
        margin-left: auto;
        a{
            position: static;
            transform: none;
            width: 36px;
            height: 36px;
            background: #fff;
            border-radius: 3px;
            &:first-child{
                margin-right: 8px;
            }
            .icon-ct{
                color: $colormain;
            }
            &:hover{
                background: $colormain;
                .icon-ct{
                    color: #fff;
                }
            }
        }
    }
}
.list-author{
    
}
.item-author{
    width: 100%;
    float: left;
    @extend %flexbox;
    align-items: center;
    margin-bottom: 30px;
    .avatar{
        width: 60px;
        height: 60px;
        display: inline-block;
        margin-right: 16px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    &.main{
        .avatar{
            width: 100px;
            height: 100px;
            margin-right: 26px;
        }
        .text{
            color: var(--color-text-2);
        }
    }
}
.name-author{
    font-size: 20px;
    font-weight: 700;
}
/*End Author*/

/*End folder-nguoi-fpt*/

/*longform*/
.section-longform{
    .list-art.grid .art_item .title-news{
        font-size: 22px;
        line-height: 1.27;
    }
}
.page-longform{
    .wrap-box-folder.style-6{
        .thumb_art{
            margin-bottom: 20px;
        }
        .title-news{
            font-size: 22px;
        }
    }
}
%label-art{
    width: 81px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 8px;
    bottom: 8px;
}
.label-longform{
    @extend %label-art;
    background: url(images/graphics/label_longform.svg) no-repeat;
}
.label-infographic{
    @extend %label-art;
    width: 101px;
    background: url(images/graphics/label_infographic.svg) no-repeat;
}
/*End longform*/

/*section-folder-podcast*/
.section-folder-podcast{
    background: url(images/graphics/folder_podcast.png) no-repeat;
    background-position: top center;
    padding: 36px 15px 30px;
}
.title-folder-podcast{
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 36px;
}
.list-podcast{
    &.list{
        display: block;
        .art_item{
            width: 100%;
            float: left;
            border: none;
            padding: 0;
            margin-bottom: 30px;
            @extend %flexbox;
            border-radius: 3px;
            overflow: hidden;
            &:hover{
                box-shadow:none;
            }
        }
        .thumb_art{
            width: 70.45454545454545%;
            flex-shrink: 0;
            margin: 0;
        }
        .content{
            width: 29.54545454545455%;
            background: #F8F8F8;
            padding: 30px;
            border-radius: 3px;
        }
        .title-news{
            font-size: 20px;
        }
        .lead{
            width: auto;
            float: none;
            margin-bottom: 16px;
        }
        .bottom-podcast{
            width: auto;
            position: static;
            padding: 0;
        }
    }
}
/*End section-folder-podcast*/

/*Multimedia*/
.section-list-images{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px 18px;
    padding: 0 30px;
    .title-news{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        padding: 100px 30px 30px;
        font-size: 26px;
        line-height: 1.15;
        color: #fff;
        font-weight: 400;
        border-radius: 5px;
        a:hover{
            color: #DADADA;
        }
    }
    .art_item{
        &:nth-child(10n + 1){
            grid-column: 1 / 3;
        }
        &:nth-child(10n + 6){
            grid-column: 3 / 5;
        }
    }
    
}
/*End Multimedia*/

/*Video*/
.section-detail-video{
    max-width: 1754px;
    padding: 0 15px;
    @extend %flexbox;
    margin:0 auto 30px;
    position: relative;
    @media (max-width: 1000px){
        flex-wrap: wrap;
        padding: 0;
    }
    .left-detail{
        width: 100%;
        float: left;
        position: relative;
        padding-right: 29.98839907192575%;
        @media (max-width: 1600px){
            padding-right:35%;
        }
        @media (max-width: 1000px){
            padding-right:0;
        }
        .thumb-video{
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            position: relative;
            img, iframe, video{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
    .right-detail{
        width: 29.98839907192575%;
        height: 100%;
        position: absolute;
        right: 6px;
        top: 0;
        background: #F8F8F8;
        flex-shrink: 0;
        padding: 35px 10px 35px 35px;
        @media (max-width: 1600px){
            width:35%;
            .ss-content{
                padding-right: 25px;
            }
        }
        @media (max-width: 1359px){
            padding: 15px 10px 35px 25px;
            .meta{
                display: none;
            }
            .ss-content{
                overflow-x: hidden;
            }
        }
        @media (max-width: 1000px){
            width: 100%;
            position: static;
        }
        .inner-right-detail{
            height: 100%;
            overflow: hidden;
            padding-right: 25px;
            
        }
        .section-comment{
            border: none;
        }
        .filter_coment{
            margin-left: auto;
            a{
                margin-left: 16px;
                margin-right: 0;
            }
        }
        .box-area-input{
            background: #fff;
            border: none;
        }
        .social .notify{right: 0;left: auto;transform: none}
    }
    .title-news{
        font-size: 30px;
        line-height: 1.333;
        margin-bottom: 16px;
    }
    .lead{
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 16px;
    }
    .meta-detail{
        align-items: center;
        font-size: 13px;
        margin-bottom: 24px;
        padding-bottom: 14px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        .cat{
            color: #4F4F4F;
            display: inline-block;
        }
        .date-time{
            color: #757575;
            display: inline-block;
            position: relative;
            padding-left: 16px;
            &:before{
                content: '';
                width: 1px;
                height: 14px;
                background: #757575;
                position: absolute;
                left: 8px;
                top: 2px;
            }
        }
    }
    .social{
        margin-left: auto;
        .item-social{
            margin-left: 20px;
            margin-right: 0;
        }
    }
    
}
.title-box{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 24px;
    @extend %flexbox;
    align-items: center;
    .inner-title-box{
        display: flex;
        align-items: center;
    }
    .icon-ct{
        color: var(--colormain);
        margin-right: 10px;
        line-height: 1;
    }
}
.list-video{
    &:before, &:after{
        content: none;
    }
    display: grid;
    grid-template-columns:repeat(4, 1fr);
    grid-gap:35px 24px;
    .art_item{
        &.feature{
            grid-column:1 / 5;
            @extend %flexbox;
            overflow: hidden;
            border-radius: 3px;
            .thumb_art{
                width: 68.18181818181818%;
                flex-shrink: 0;
                margin: 0;
                @media(max-width:768px){
                    width: 55%;
                }
            }
            .title-news{
                font-size: 26px;
                line-height: 1.269;
                font-weight: 700;
                margin-bottom: 16px;
            }
            .lead{
                font-size: 16px;
                margin-bottom: 16px;
                @media(max-width:768px){
                    display: none;
                }
            }
            .social{
                margin-left: auto;
            }
            .content{
                background: #F3F3F3;
                padding: 35px;
                border-radius: 3px;
                @media(max-width:768px){
                    padding: 30px 15px;
                }
            }
        }
    }
    .thumb_art{
        margin-bottom: 16px;
    }
    .title-news{
        font-weight: 400;
        margin-bottom: 8px;
        font-size: 16px;
    }
}
.meta{
    @extend %flexbox;
    align-items: center;
}
.item-meta{
    position: relative;
    &:not(:first-child){
        padding-left: 16px;
        &:before{
            content: '';
            width: 1px;
            height: 14px;
            background: #BDBDBD;
            position: absolute;
            left: 8px;
            top: 2px;
        }
    }
}
.btn-like{
    color: var(--color-2);
    border: 1px solid #0065D3;
    border-radius: 3px;
    width: 110px;
    height: 36px;
    text-align: center;
    font-size: 14px;
    margin-right: 16px;
    @extend %flexbox;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon-ct{
        margin-right: 8px;
        font-size: 18px;
    }
}
.count-view{
    font-size: 13px;
    color: #4F4F4F;
    @extend %flexbox;
    align-items: center;
    .icon-ct{
        font-size: 20px;
        margin-right: 8px;
    }
}
.icon-play{
    cursor: pointer;
    width: 97px;
    height: 97px;
    border-radius: 50%;
    border: 1px solid #fff;
    @extend %flexbox;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .icon-ct{
        color: #fff;
        font-size: 28px;
    }
}
/*End Video*/

/*Tags*/
.header-page{
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    .tag-name{
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 20px;
        font-weight: 700;
    }
}
.social{
    font-size: 14px;
    align-items: center;
    font-family: $fontmain;
    .text{
        margin-right: 10px;
        font-size: 12px;
    }
    .item-social{
        margin-right: 20px;
        cursor: pointer;
        position: relative;
        &:hover{
            .icon-ct{
                color: $color-2;
            }
        }
    }
    .icon-ct{
        color: #757575;
        font-size: 18px;
    }
    .notify{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        top: calc(100% + 10px);
        background: rgba(0,0,0,0.7);
        color: #fff;
        padding: 5px 10px;
        border-radius: 3px;
    }
}
/*End Tags*/