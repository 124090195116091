@import "var.scss";
@import "lightbox.scss";
@import "popup-css.scss";
@import "list-art.scss";
@import "box-folder.scss";
@import "folder.scss";
@import "search.scss";
@import "contact.scss";  
@import "darkmode.scss"; 

.only-mobile{
    display: none;
    @media(max-width:767px){
        display: block !important;
    }
}
.title-news{
    font-family: $font-title;
    font-weight: bold;
    color: var(--color-text-1);
    .label-live{
        width: 70px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        display: inline-block;
        color: #fff;
        font-size: 12px;
        background: #DA2121;
        border-radius: 35px;
        font-weight: normal;
        position: relative;
        top: -3px;
        margin-right: 10px;
        .icon-ct{
            margin-right: 5px;
        }
    }
}
.lead{
    color: var(--color-text-2);
}
.cat{
    display: inline-block;
    font-size: 13px;
    color: var(--color-text-2);
    font-family: $font-title;
    &:hover{
        color: $colormain;
    }
}
/*section feature*/
.section-feature{
    .art_item{
        &.feature{
            width: 100%;
            max-width: 730px;
            margin-bottom: 30px;
            .thumb_art{
                width: 100%;
                float: left;
            }
            .content{
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                width: 100%;
                float: left;
                position: absolute;
                left: 0;
                bottom: 0;
                color: #fff;
                padding: 54px 24px 24px;
                border-radius: 5px;
                .title-news{
                    color: #fff;
                    font-size: 28px;
                    line-height: 1.17;
                    margin-bottom: 8px;
                    &:hover a{
                        color: #DADADA;
                    }
                }
                .cat{
                    color: #fff;
                    &:hover{
                        color: $colormain;
                    }
                }
            }
        }
    }
    .list{
        width: 380px;
        padding-left: 30px;
        .art_item{
            &:not(:last-child){
                border-bottom: 1px solid var(--color-border);
                margin-bottom: 18px;
                padding-bottom: 18px;
            }
        }
        .title-news{
            font-size: 18px;
            line-height: 1.3;
            margin-bottom: 14px;
        }
    }
}
/*End section feature*/

/*Gallery*/
.section-gallery{
    background: url(images/graphics/bg_gallery.png) no-repeat;
    background-size: cover;
    padding: 30px 0;
    .title-section{
        .sub-cate{
            position: relative;
            &.active, &:hover{
                &:before{
                    content: '';
                    width: 22px;
                    height: 2px;
                    background: var(--colormain);
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -3px;
                }
            }
        }
    }
    .art_item{
       // width: 45.625%;
        transition: all .3s ease;
        &.swiper-slide-active{
            .thumb_art{
                &:before{ 
                    content: none;
                }
            }
            .content{
                opacity: 1;
                visibility: visible;
            }
            .icon-play{
                opacity: 1;
            }
        }
    }
    .thumb_art{
        margin-bottom: 16px;
        position: relative;
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background: rgba(0,0,0,0.6);
            z-index: 1;
        }
    }
    .content{
        position: relative;
        padding-right: 30%;
        opacity: 0;
        visibility: hidden;
    }
    .social{
        position: absolute;
        right: -20px;
        top: 5px;
    }
    .title-news{
        font-size: 24px;
        line-height: 1.25;
        margin-bottom: 16px;
        
    }
    .lead{
        line-height: 1.4;
    }
    .icon-play{
        opacity: 0;
    }
    .btn-prev, .btn-next{
        width: 60px;
        height: 60px;
        background: #fff;
        border-radius: 0;
        .icon-ct{
            color: $colormain;
            font-size: 22px;
        }
        &:hover{
            .icon-ct{
                color: #fff;
            }
        }
    }
    .btn-prev{
        left: 19%;
        @media (max-width:1440px){
            left: 17%;
        }
        
        @media (max-width:1024px){
            left: 15%;
        }
    }
    .btn-next{
        right: 19%;
        @media (max-width:1440px){
            right: 17%;
        }
        @media (max-width:1024px){
            right: 15%;
        }
    }
}
/*End Gallery*/

/*section-nguoi-fpt*/
.section-nguoi-fpt{
    background: var(--color-3);
    padding: 30px 0 0; 
}
/*End section-nguoi-fpt*/

/*section-gocnhin*/
.section-gocnhin{
    background: url(images/graphics/bg_gocnhin.png) no-repeat;
    padding: 60px 50px;
    margin-bottom: 30px;
    .title-section{
        margin-bottom: 60px;
    }
}
.list-nhanvat{
    text-align: center;
    .thumb_art{
        margin-bottom: 24px;
        max-width: 290px;
        img{
            border-radius: 50%;
        }
    }
    .title-news{
        font-size: 30px;
        font-weight: 600;
        color: $colormain;
        margin: 0 -90px;
        @media screen and (max-width: 1440px){
	        font-size: 26px;
        }
    }
    .author{
        color: var(--color-text-2);
        font-size: 16px;
        line-height: 2.875;
        white-space: nowrap;
        margin: 0 -100px;
    }
    .title-news, .author{
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
    }
    .swiper-slide-active{
        .title-news, .author, .icon-quotes{
            opacity: 1;
            visibility: visible;
        }
    }
    .icon-quotes{
        width: 59px;
        height: 59px;
        background: $colormain;
        border: 3px solid #FFFFFF;
        border-radius: 50%;
        font-size: 28px;
        color: #fff;
        @extend %flexbox;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 10px;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease;
    }
    .btn-prev, .btn-next{
        top: calc(50% - 105px);
    }
}
/*End section-gocnhin*/

/*section-community*/
.section-community{
    max-width: 1200px;
    background: var(--color-3);
    border-radius: 3px;
    padding: 30px 50px;
    margin-bottom: 35px;
    .thumb_art{
        margin-bottom: 16px;
    }
    .title-news{
        font-size: 16px;
        line-height: 1.25;
    }
}
%btn-slide{
    width: 24px;
    height: 61px;
    @extend %flexbox;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: calc(50% - 30px);
    transform: translateY(-50%);
    transition: all .3s ease;
    z-index: 1;
    &:hover{
        background: $colormain;
    }
    .icon-ct{
        color: #fff;
        font-size: 14px;
    }
}
.btn-prev{
    @extend %btn-slide;
    left: 0;
    border-radius: 0px 3px 3px 0px;
}
.btn-next{
    @extend %btn-slide;
    right: 0;
    border-radius: 3px 0px 0px 3px;
}
/*End section-community*/

/*Section Podcast*/
.section-podcast{
    margin-bottom: 30px;
}
.list-podcast{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    .art_item{
        background: #fff;
        border: 1px solid #DFC2B0;
        border-radius: 3px;
        padding: 24px 24px 70px;
        transition: all .3s ease;
        &:hover{
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        }
    }
    .thumb_art{
        width: 130px;
        float: left;
        margin-right: 16px;
    }
    .title-news{
        font-size: 18px;
        line-height: 1.33;
    }
    .lead{
        width: 100%;
        float: left;
        margin-top: 15px;
        font-size: 16px;
        line-height: 1.4;
    }
    .bottom-podcast{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 24px 24px;
        @extend %flexbox;
        align-items: center;
        .icon{
            width: 35px;
            height: 35px;
            margin-right: 13px;
            @extend %flexbox;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--color-2); 
            border-radius: 50%;
            color: var(--color-2);
        }
        .text{
            font-size: 14px;
            margin-right: 5px;
        }
        .time{
            color: #757575;
            font-size: 13px;
            position: relative;
            top: 1px;
        }
    }
}
/*End Section Podcast*/

/*box-editors-pick*/
.box-editors-pick{
    width: 100%;
    float: left;
    background: #FFF7F2;
    padding: 20px 16px;
    border-radius: 3px;
    .header-editors-pick{
        @extend %flexbox;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
        .icon{
            width: 30px;
            margin-right: 10px;
        }
    }
    .title-news{
        font-size: 16px;
        line-height: 1.375;
        font-weight: 600;
        a{
            padding-right: 20px;
            position: relative;
            display: block;
        }
        &:not(:last-child){
            border-bottom: 1px solid var(--color-border);
            padding-bottom: 24px;
            margin-bottom: 16px;
        }
        .icon-ct{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: #B4B4B4;
            font-size: 18px;
        }
    }
}
/*End box-editors-pick*/

.readmore{
    text-align: center;clear: both;
    .inner-readmore{
        width: 123px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        display: inline-block;
        font-size: 14px;
        color: $colormain;
        border: 1px solid $colormain;
        border-radius: 3px;
        transition: all .3s ease;
        &:hover{
            background: $colormain;
            color: #fff;
        }
    }
}

/*page-404*/
.page-404{
    
    .container.mb70{
        text-align: center;
    }
    .img-404{
        margin: 30px 0 35px;
    }
    .title-404{
        font-size: 30px;
        line-height: 1.2;
        margin-bottom: 14px;
    }
    .note{
        font-size: 20px;
        line-height: 1.2;
        color: var(--color-text-2);
        margin-bottom: 30px;
    }
    .btn{
        width: 160px;
        display: inline-block;
        &:hover{
            color: #fff;
        }
    }
    @media(max-width:767px){
        .footer, .header, .main-nav{
            display: none;
        }
    }
}
/*End page-404*/

/*Box thăm dò*/
.boxthamdoykien{
    width: 100%;
    max-width: 300px;
    float: left;
    padding: 26px 26px 80px;
    background: url(images/graphics/bg_thamdo.jpg) no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    font-family: "SFProDisplay",serif !important;
    .title_box_category{
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 5px;
    }
    .title_thamdo{
        font-size: 22px;
        line-height: 1.18;
        margin-bottom: 10px;
        font-weight: 600;
        font-family: "SFProDisplay",serif !important;
    }
    .tb_servey{
        .row-servey{
            width: 100%;
            height: 40px;
            background: #9D410A;
            border-radius: 3px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            input{
                width: 18px;
                height: 18px;
                float: left;
                margin-right: 12px;
            }
            .w20{
                width: 30px !important;
                flex-shrink: 0;
            }
            .w130{
                width: 100%;
                height: 100%;
                
            }
            label{
                width: 100%;
                height: 100%;
                cursor: pointer;
                display: flex;
                align-items: center;
            }
        }
        .btn_bieuquyet{
            width: 86px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff !important;
            background: #08509F !important;
            border-radius: 3px;
            margin-right: 14px;
            border: none;
            font-family: "SFProDisplay",serif !important;
             
        }
        .bottom-servey{
            display: flex;
            align-items: center;
            margin-top: 4px;
            font-family: "SFProDisplay",serif !important;
        }
        a:hover{
            color: #fff;
        }
    }
    .logo-ct{
        width: 80px;
        position: absolute;
        left: 26px;
        bottom: 20px;
        img{
            float: left;
        }
    }
    .txt_main_category{
        color: #fff !important;
        margin-left: 0 !important;
        font-family: "SFProDisplay",serif !important;
    }
}
.title_box_category span{color: #fff !important;font-family: "SFProDisplay",serif !important;}
#boxthamdoykien .txt_xemketqua{
    color: #fff !important;
}
.fck_detail{
    #boxthamdoykien .tb_servey td, #boxthamdoykien .tb_servey td{padding-bottom: 0;}
}
/*End Box thăm dò*/

/*Browser Safari */

_::-webkit-full-page-media,
_:future,
:root .header .nav__left {
    padding-left: 110px;
    flex-grow: inherit;
}

_::-webkit-full-page-media,
_:future,
:root .header .nav__right {
    flex-grow: inherit;
    padding-right: 110px;
}

.ads_footer{
    text-align: center;
}
// Footer
@import "footer.scss";
