.row-input{
    width: 100%;
    float: left;
    .text{
        display: inline-block;
        margin-bottom: 12px;
        font-size: 13px;
        @media(max-width:600px){
            font-size: 14px;
        }
    }
}
.section-contact{
    .title-box{
        font-size: 30px;
        margin-bottom: 30px;
    }
    .main-content{
        width: 39.09090909090909%;
        @media(max-width:1000px){
            width: 40%;
        }
        @media(max-width:767px){
            width: 100%;
        }
    }
    .sidebar{
        width: 44.54545454545455%;
        margin-left: 9.090909090909091%;
        padding-left: 0;
        display: block;
        .box-info{
            padding: 30px 30px 24px;
            border-radius: 3px;
            background: var(--color-3);
            font-size: 16px;
            p{
                margin-bottom: 10px;
                line-height: 1.5;
            }
        }
        .title-box-info{
            display: block;
            font-size: 20px;
            line-height: 1.2;
            margin-bottom: 10px;
        }
        @media(max-width:1000px){
            width: 55%;
            margin-left: 5%;
        }
        @media(max-width:767px){
            width: 100%;
            margin: 0;
        }
    }
    .form-contact{
        margin-bottom: 30px;
        .row-input{
            margin-bottom: 16px;
        }
        textarea{
            height: 270px;
        }
        .btn{
            width: 130px;
            margin-right: 16px;
            background: $colormain;
        }
        input[type="reset"]{
            width: auto;
            border: none;
            background: none;
        }
        @media(max-width:767px){
            input[type="reset"]{
                display: none;
            }
            .btn{
                width: 100%;
            }
        }
    }
    @media(max-width:767px){
        display: block;
    }
}
.file-input{
    width: 180px;
    height: 40px;
    position: relative;
    @extend %flexbox;
    align-items: center;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 0 4px 0 18px ;
    overflow: hidden;
    input{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
    }
    .text-file{
        font-size: 14px;
        position: absolute;
        left: 15px;
    }
    .icon{
        width: 29px;
        height: 29px;
        background: #E5E5E5;
        border-radius: 3px;
        font-size: 16px;
        @extend %flexbox;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        position: absolute;
        right: 10px;
    }
}

/*Dev*/
.file-upload{
    width: 120px;
    overflow: hidden;
}
/*End Dev*/