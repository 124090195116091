.list-art{
    width: 100%;
    float: left;
    font-family: $font-title;
    .art_item{
        width: 100%;
        float: left;
        display: block;
        &:not(:first-child){
            border-top: 1px solid var(--color-border);
            padding-top: 30px;
            margin-top: 30px;
        }
        .thumb_art{
            width: 250px;
            float: left;
            margin-right: 24px;
        }
        .title-news{
            font-size: 22px;
            line-height: 1.27;
            margin-bottom: 16px;
        }
        .lead{
            font-size: 16px;
            margin-bottom: 16px;
        }
        .content{
            overflow: hidden;
            border-radius: 3px;
        }
    }
    &.grid{
        grid-gap: 30px;
        &.col-2{
            grid-template-columns: repeat(2, 1fr);
        }
        &.col-3{
            grid-template-columns: repeat(3, 1fr);
        }
        &.col-4{
            grid-template-columns: repeat(4, 1fr);
        }
        .art_item{
            margin: 0;
            padding: 0;
            border: none;
            .thumb_art{
                width: 100%;
                margin: 0 0 16px;
            }
            .title-news{
                font-size: 20px;
                line-height: 1.3;
            }
        }
    }
}
.thumb_art{
    position: relative;
}
.art_item{
    position: relative;
}